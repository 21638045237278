<template>
  <base-template rgb="255,255,255" use-bstyle>
    <div class="gcash-box clearfix">
      <div class="top-icon">
        <img src="~@/assets/images/public/top-bg-icon.png" alt="">
      </div>

      <div class="step-box">
        <p class="pay-title">UnionBank {{ $t('userCenter.paymentMethod') }}</p>
        <img src="~@/assets/images/stayPay/paymethod/ussc-first-icon.png" alt="">
        <p class="remark">Customer Goes to any branch of USSC and get Bills Payment Form from the Cashier</p>
        <div class="next-icon"></div>
        <img src="~@/assets/images/stayPay/paymethod/ussc-second-icon.png" alt="">
        <p class="remark">
          <span> Customer fill up the following:</span>
          <span>1. Merchant Name: SKYPAY2. First Name3. Middle Name</span>
          <span>4. Last Name5. Mobile No.6. Email7. Account No:</span>
          <span>(eg:SKY98200624108)8. Amount</span>
        </p>
        <div class="next-icon"></div>
        <img src="~@/assets/images/stayPay/paymethod/ussc-last-icon.png" alt="">
        <p class="remark">Cashier will process the payment and provides printed receipt to Customer as a proof that the payment has been successfully posted to SKYPAY</p>
        <p class="last-remark">ASMS notification will be also sent to the provided mobile number of the customer.</p>
      </div>
    </div>
  </base-template>
</template>

<script>

export default {
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.gcash-box{
  position: relative;
  .top-icon{
    text-align: right;
    img{
      width: 428px;
      margin-right: -30px;
    }
  }
  .pay-title{
    height: 100px;
    line-height: 100px;
    font-size: 32px;
    font-weight: 500;
    color: #005FE9;
    width: 100%;
    background: url('~@/assets/images/stayPay/pay-type-tbj-icon.png') no-repeat center center;
    background-size: 100%;
    text-align: center;
    margin-bottom: 32px;
  }
  .step-box{
    background: #fff;
    padding-bottom: 20px;
    border-radius: 32px;
    margin-top: -92px;
    z-index: 2;
    position: relative;
    .remark{
      font-size: 24px;
      color: #000000;
      line-height: 36px;
      margin-bottom: 20px;
      padding: 0 20px;
      span{
        display: block;
      }
    }
    .next-icon{
      height: 80px;
      margin-bottom: 12px;
      background: url('~@/assets/images/stayPay/paymethod/ussc-next-icon.png') no-repeat center center;
      background-size: 80px;
    }
    img{
      width: 100%;
    }
  }
}

:deep(.child-title){
  display: none;
}
:deep(.headerbase){
  background: rgba(0, 95, 233, 0);
}
:deep(.is-content){
  background: #005FE9FF;
  border-radius: 0;
  margin-top: -88px;
  img{
    width: 100%;
  }
}
:deep(.headerbase .headerpulic-left){
  background: url('~@/assets/images/public/head-back-icon.png') no-repeat center center;
  background-size: 100%;
}
.last-remark{
  font-size: 24px;
  color: #CCCCCC;
  line-height: 36px;
  padding: 0 20px;
  margin-top: -12px;
}
</style>
